    .explore-img-gradient {
      position: relative;
    }

    .explore-img-gradient::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180.17deg, rgba(25, 44, 54, 0) 42.56%, #192C36 99.85%);
    }

    /* ...........Image slider style override............. */

    .rsis-image {
      background-position: center !important;
      /* object-fit: fill; */
      position: relative;

    }

    .rsis-image::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      /* background: linear-gradient(180.17deg, rgba(25, 44, 54, 0) 42.56%, #192C36 99.85%); */
    }

    /* ...........Image slider style override............. */

    .img-gradient {
      position: relative;
    }

    .img-gradient::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      /* background: linear-gradient(180.17deg, rgba(25, 44, 54, 0) 42.56%, #192C36 99.85%); */
    }